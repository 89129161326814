import axios from 'axios';
import {REQUESTS_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import {RequestMetaDTO} from "@/dto/request/RequestMetaDTO";
import Page from "@/dto/taxation/Page";
import QueryUtils from "@/utils/QueryUtils";
import {RequestType} from "@/constants/request/RequestsStatuses";
import { WorkspaceType } from "@/dto/auth/Workspace";

class RequestService {

    getAllRequests(
      pageNum: number,
      type: RequestType | null,
      status: string | null,
      customerNameFilter: string | null,
      assigneeId: number | null,
      createdById: number | null,
      createdDateFrom: string | null,
      createdDateTo: string | null,
      section: string | null,
      profileId: number | null,
      profileType: WorkspaceType | null,
    ) {
      const queryString = QueryUtils.buildQueryString(
  ["pageNum", pageNum],
  ["type", type],
        ["status", status],
        ["customerNameFilter", customerNameFilter],
        ["assigneeId", assigneeId],
        ["createdById", createdById],
        ["createdDateFrom", createdDateFrom],
        ["createdDateTo", createdDateTo],
        ["section", section],
        ["profileId", profileId],
        ["profileType", profileType],
      )
        return axios.get<Page<RequestMetaDTO>>(REQUESTS_ENDPOINT + queryString, {headers: authHeader()});
    }

}

export default new RequestService();
