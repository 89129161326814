

















import {Vue, Component, Prop} from 'vue-property-decorator';
import CompanySearch from "@/components/common/CompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import CreateW9CRPayload from "@/dto/request/w9cr/CreateW9CRPayload";
import W9CRService from "@/services/request/W9CRService";
import {namespace} from "vuex-class";

const AppModule = namespace("App");

@Component({
  components: {CompanySearch, PortalInput}
})
export default class CreateW9CR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  onCreated!: () => void

  private company: CompanyDTO | null = null;

  private contractorNames: Array<string> = [];

  private newContractor = "";

  private addContractor(){
    this.contractorNames.push(this.newContractor);
    this.newContractor = "";
  }

  private create(){
    this.startLoading();
    const payload = new CreateW9CRPayload({companyId: this.company?.id, contractors: this.contractorNames});
    W9CRService.create(payload).then(
        ok => {
          this.stopLoading();
          this.onCreated();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

}
