




















import {Component, Prop, Vue} from 'vue-property-decorator';
import PersonSearch from "@/components/common/PersonSearch.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import CreateITRQPayloadDTO from "@/dto/request/itrq/CreateITRQPayloadDTO";
import {namespace} from "vuex-class";
import IndividualTaxationService from "@/services/request/IndividualTaxationService";

const AppModule = namespace("App");

@Component({
  components: {PersonSearch}
})
export default class CreateITRQ extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  onCreated!: () => void

  person: PersonDTO | null = null;
  payload = new CreateITRQPayloadDTO();

  create() {
    if (!this.person) {
      return;
    }
    this.payload.personId = this.person.id;
    this.startLoading();
    IndividualTaxationService.createQuestionnaire(this.payload).then(
        ok => {
          this.stopLoading();
          this.onCreated();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }


}
