






























































import {Component, Prop, Vue} from 'vue-property-decorator';
import OtherCompanyBankProductDTO, {BankProductType} from "@/dto/company/OtherCompanyBankProductDTO";
import {namespace} from "vuex-class";
import CompanyService from "@/services/CompanyService";
import PortalInput from "@/components/common/PortalInput.vue";
import CBSRService from "@/services/request/CBSRService";
import CreateCBSRPayload, {
  CreateCBSRPayloadAccount,
  CreateCBSRPayloadPeriod
} from "@/dto/request/cbsr/CreateCBSRPayload";
import {Period} from "@/constants/DateTimeConstants";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import OtherCompanyBankProducts from "@/components/profile/company/OtherCompanyBankProducts.vue";

const AppModule = namespace("App");

@Component({
  computed: {

    BankProductType() {
      return BankProductType
    }

  },
  components: {SimpleCompanySearch, PortalCheckbox, PortalTextarea, PortalDate, PortalInput}
})
export default class CreateCBSR extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @Prop()
  companyId!: number;

  @Prop({default: null})
  onCreated!: (() => void) | null;

  bankAccounts: Array<[OtherCompanyBankProductDTO, boolean]> = []; // acc -> checked

  selectedAccounts: Array<[[CreateCBSRPayloadAccount, OtherCompanyBankProductDTO], [Period | null, number | null], [Date | null, Date | null]]> = []

  private periodStage = false;

  private payload = new CreateCBSRPayload({companyId: this.companyId});

  private textMessage = "";

  private selectCompany(company: CompanyDTO) {
    this.payload.companyId = company.id;
    this.loadAccounts();
  }


  mounted() {
    this.loadAccounts();
  }

  loadAccounts() {
    this.startLoading();
    CompanyService.getOtherBankAccounts(this.payload.companyId).then(
        ok => {
          this.bankAccounts = ok.data.map(acc => [acc, false]);
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  accountsSelected() {
    this.selectedAccounts = [];
    this.bankAccounts
        .filter(acc => acc[1])
        .forEach(a => {
          const pl = new CreateCBSRPayloadAccount({otherBankAccountId: a[0].id as number});
          this.selectedAccounts.push(
              [
                [pl, a[0]],
                [this.$dateUtils.monthToPeriod(this.$dateUtils.getPreviousMonth()), Math.max(...this.$years)],
                [null, null]
              ]);
          this.payload.accounts.push(pl);
        })
    this.payload.accompanyingText = this.textMessage;
    this.periodStage = true;
  }

  addPeriod(tuple: [[CreateCBSRPayloadAccount, OtherCompanyBankProductDTO], [Period, number], [Date | null, Date | null]]) {
    tuple[0][0].periods.push(new CreateCBSRPayloadPeriod({period: tuple[1][0], year: tuple[1][1]}));
    tuple[1][0] = this.$dateUtils.monthToPeriod(this.$dateUtils.getPreviousMonth());
    tuple[1][1] = Math.max(...this.$years);
  }

  addCreditPeriod(tuple: [[CreateCBSRPayloadAccount, OtherCompanyBankProductDTO], [Period | null, number | null], [Date, Date]]) {
    tuple[0][0].periods.push(new CreateCBSRPayloadPeriod({dateFrom: tuple[2][0], dateTo: tuple[2][1]}))
  }

  create() {
    this.startLoading();
    CBSRService.createRequest(this.payload).then(
        ok => {
          this.stopLoading();
          if (this.onCreated) {
            this.onCreated();
          }
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  getBankTypeName(type: BankProductType) {
    if (type == null) {
      return "undefined"
    } else {
      return `${type}`
    }
  }

}
