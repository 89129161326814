import {Month, Period} from "@/constants/DateTimeConstants";

export default class CreateCBSRPayload {
    public companyId!: number;

    public accounts: Array<CreateCBSRPayloadAccount> = [];

    public accompanyingText: string | null = null;

    constructor(init?: Partial<CreateCBSRPayload>) {
        Object.assign(this, init);
    }
}

export class CreateCBSRPayloadAccount {
    public otherBankAccountId!: number;
    public periods: Array<CreateCBSRPayloadPeriod> = [];

    constructor(init?: Partial<CreateCBSRPayloadAccount>) {
        Object.assign(this, init);
    }
}

export class CreateCBSRPayloadPeriod {
    period!: Period;

    year!: number;

    dateFrom!: Date;

    dateTo!: Date;

    constructor(init?: Partial<CreateCBSRPayloadPeriod>) {
        Object.assign(this, init);
    }

}